import React from 'react';
import SEO from '../components/SEO';
import AnimatedHeading from '../components/AnimatedHeading';
import { StaticImage } from 'gatsby-plugin-image';
import CTA from '../components/Cta';
import FadeIn from '../components/Animations/FadeIn';

const AboutPage = () => {
	return (
		<>
			<SEO
				title="About"
				pathname="/about"
				desc="A collaboration of Architects & Master Craftsman"
			/>
			<AnimatedHeading text="The ATTIQK philosophy" />
			<FadeIn>
				<section className="grid grid-cols-1 md:grid-cols-3 gap-8 p-6 md:p-8">
					<div className="richtext">
						<p>
							Attiqk was founded to create design-driven
							developments influenced by the environment
							surrounding us.
						</p>
						<p>
							Striving to produce highly detailed and executed
							interiors that then reflect outwardly to form
							effortless exteriors. We believe the built
							environment should mirror the surrounds we inhabit,
							with our strong belief that beautiful architecture
							can shape our lives for the better.
						</p>
						<p>
							Our goal is to use a collaborative approach between
							Architect & Builder, to create timeless designs that
							will provoke a change in the way we see development.
						</p>
					</div>
					<div className="col-span-2">
						<div className="aspect-square">
							<StaticImage
								src="../images/about.jpg"
								alt="Laine exterior"
								className="w-full h-full object-cover"
							/>
						</div>
					</div>
				</section>
			</FadeIn>
			<FadeIn>
				<CTA />
			</FadeIn>
		</>
	);
};

export default AboutPage;
